/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import '../organisms/styles.css';

export default () => (
  <div id="main">
    <div className="fof">
      <h1>Error 404</h1>
    </div>
  </div>
);
