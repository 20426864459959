/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import Dates from '../ui/molecules/Dates';
import Announcements from '../ui/molecules/Announcement';
import { useMasjid } from '../context';
import NimazTimes from '../ui/molecules/NimazTimes';
import Flyers from '../ui/molecules/Flyers';
import { theme } from '../settings/theme';
import { CropFree } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

type propTypes = {
  params: {
    id: number;
  };
};

//styles
const useStyles = makeStyles({
  time: {
    color: 'black',
    fontSize: '18px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dates: {
    color: 'black',

    borderLeft: `3.5px solid ${theme.palette.primary.main} `,
    height: '100%',
  },
  crop: {
    color: 'black',
    backgroundColor: `${theme.palette.secondary.main}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bodyCenter: {
    textAlign: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    flex: 2,
  },
  masjidName: {
    height: '56px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.secondary.dark,
  },
  footer: {
    flex: 1,
    backgroundColor: `${theme.palette.primary.main}`,
    display: 'flex',
    alignItems: 'center',
  },
});

const TvDisplayLayout: FC<propTypes> = (props: propTypes) => {
  const handle = useFullScreenHandle();
  const [time, setTime] = useState<string>();
  const styles = useStyles();
  const { setCurrentMasjidId, newmasjidData } = useMasjid();

  const { id } = props.params;
  useEffect(() => {
    id && setCurrentMasjidId(id);
    timer();
  }, []);

  const timer = () => {
    setInterval(() => {
      const currentTime = new Date().toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
      });
      const firstTwo = currentTime.slice(0, 2);

      if (firstTwo === '00') {
        const changedCurrentTime = currentTime.replace(/[0-9]{1,2}/, '12');
        setTime(changedCurrentTime);
      } else {
        setTime(currentTime);
      }
    }, 1000);
  };
  return (
    <>
      <FullScreen handle={handle}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100vw', height: '100vh' }}>
          <Box sx={{ flex: 1, height: '100%' }}>
            <Grid
              container
              columns={12}
              style={{ background: `${theme.palette.secondary.main}`, height: '100%' }}
              textAlign={'center'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Grid item xs={4} className={styles.time}>
                <Typography variant={'h3'} alignItems={'center'}>
                  {time}
                </Typography>
              </Grid>
              <Grid item xs={7.5} className={styles.dates}>
                <Dates />
              </Grid>
              <Grid item xs={0.5} className={styles.crop}>
                <IconButton onClick={handle.active ? handle.exit : handle.enter}>
                  <CropFree color="primary" />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ flex: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
              {!newmasjidData.showFlyer && !newmasjidData.showQuran && (
                <Box
                  sx={{ flex: 0.5 }}
                  style={{
                    borderRight: `1.5px solid ${theme.palette.secondary.dark}`,
                    height: '100%',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  {newmasjidData && (
                    <Typography variant="h2" color={theme.palette.primary.dark} align="center">
                      {newmasjidData.name}
                    </Typography>
                  )}
                </Box>
              )}
              <Box sx={{ flex: 1 }} style={{ borderRight: `3.5px solid ${theme.palette.secondary.dark}` }}>
                {time === undefined ? null : <NimazTimes isFullScreen={handle.active} time={time} />}
              </Box>
              {newmasjidData.tvDisplayFlyers && (newmasjidData.showFlyer || newmasjidData.showQuran) && (
                <Box
                  sx={{
                    flex: 2,
                    color: 'black',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box className={styles.masjidName}>
                    {newmasjidData && (
                      <Typography variant="h4" color="white">
                        {newmasjidData.name}
                      </Typography>
                    )}
                  </Box>

                  <Box>
                    <Flyers />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              height: '100%',

              background: `${theme.palette.primary.main}`,
            }}
          >
            <Box sx={{ height: '100%', alignItems: 'center', flex: 1 }}>
              <Announcements />
            </Box>
          </Box>
        </Box>
      </FullScreen>
    </>
  );
};
export default TvDisplayLayout;
