import React, { FunctionComponent } from 'react';
import { useMasjid } from '../../context';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Typography from '@mui/material/Typography';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Quran from './Quran';
import { theme } from '../../settings/theme';
const Flyers: FunctionComponent = () => {
  const { newmasjidData } = useMasjid();

  const flyerArray =
    newmasjidData.showFlyer && newmasjidData.showQuran
      ? [...newmasjidData.tvDisplayFlyers, { title: 'quran', description: '', imageUrl: '' }]
      : newmasjidData.showFlyer && !newmasjidData.showQuran
      ? [...newmasjidData.tvDisplayFlyers]
      : [{ title: 'quran', description: '', imageUrl: '' }];

  return newmasjidData && newmasjidData.tvDisplayFlyers ? (
    <div
      style={{
        height: '65vh',
        backgroundColor: `#87BBA2`,
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <CarouselProvider
        naturalSlideWidth={10}
        naturalSlideHeight={10}
        totalSlides={flyerArray.length}
        interval={8000}
        infinite={true}
        isPlaying={true}
      >
        <Slider>
          {flyerArray.map((item, index) => {
            const flyerWidth = item.description ? '100%' : 'auto';
            return (
              <Slide index={index} key={index}>
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '65vh',
                  }}
                >
                  {newmasjidData.showFlyer && index < newmasjidData.tvDisplayFlyers.length ? (
                    <>
                      {item.title && item.description && (
                        <div
                          style={{
                            flex: 1,
                            background: 'rgba(255, 255, 255, 0.2)',
                            boxShadow: ' 0 0 100px rgba(0, 0, 0, 0.2)',
                            backdropFilter: 'blur(30px)',
                            padding: '10px 20px 10px 20px',
                            marginRight: '20px',
                            borderRadius: '20px',
                            border: '2px solid rgba(255, 255, 255, 0.2)',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography gutterBottom variant="h3" align="center">
                            {item.title}
                          </Typography>
                          <Typography variant="h5" color="text.secondary" align="center">
                            {item.description}
                          </Typography>
                        </div>
                      )}
                      {item.imageUrl && (
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                          <img
                            src={item.imageUrl}
                            style={{
                              justifyContent: 'center',
                              width: flyerWidth,
                              maxHeight: '60vh',
                            }}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    newmasjidData.showQuran && (
                      <div style={{ flex: 0.7, height: '100%', alignItems: 'center' }}>
                        <Quran />
                      </div>
                    )
                  )}
                </div>
              </Slide>
            );
          })}
        </Slider>
      </CarouselProvider>
    </div>
  ) : null;
};
export default Flyers;
