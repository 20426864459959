import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';

import './index.css';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './settings/theme';
import { queryClient } from './api/index';
import { APIContextProvider } from './context';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <APIContextProvider>
        <App />
      </APIContextProvider>
    </QueryClientProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);
