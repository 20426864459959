import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#075E55',
    },
    secondary: {
      main: '#F0F7EE',
      dark: '#075E55',
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      variants: [],
    },
  },
});

export { theme };
