import { useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useWindowDimensions = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerHeight > 800 ? true : false);

  const handleResize = () => setIsDesktop(window.innerHeight > 800 ? true : false);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isDesktop;
};
