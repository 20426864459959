import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material';
import React, { FC, useState, useEffect, useCallback } from 'react';
import { useMasjid } from '../../context';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
type propTypes = {
  isFullScreen?: boolean;
  time: string | unknown;
};
//styling for table rows and cells
const NimazTimes: FC<propTypes> = ({ isFullScreen, time }: propTypes) => {
  const isDesktop = useWindowDimensions();
  const [nextSalahIndex, setNextSalahIndex] = useState<number | null>();
  useEffect(() => {
    time && setNextSalahTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,

      fontSize: isDesktop ? 24 : 14,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: isDesktop ? 28 : 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  }));
  const { newmasjidData } = useMasjid();

  const namaazTimeSlots = [];

  newmasjidData.tvDisplayPrayerTimeItems != undefined &&
    newmasjidData.tvDisplayPrayerTimeItems.forEach((item) => {
      namaazTimeSlots.push(item.timing);
    });
  const getNearestTimeSLot = () => {
    const currentTime = new Date();
    const currentMinutes = currentTime.getHours() * 60 + currentTime.getMinutes();

    const timeSlots = namaazTimeSlots;
    timeSlots.sort(function (a, b) {
      const timeA = new Date('1970/01/01 ' + a);
      const timeB = new Date('1970/01/01 ' + b);
      return timeA.getHours() * 60 + timeA.getMinutes() - timeB.getHours() * 60 - timeB.getMinutes();
    });

    let nearestTimeSlot = '';
    let nearestTimeSlotDifference = Infinity;

    for (let i = 0; i < timeSlots.length; i++) {
      const timeSlot = new Date('1970/01/01 ' + timeSlots[i]);
      const timeSlotMinutes = timeSlot.getHours() * 60 + timeSlot.getMinutes();
      const timeSlotDifference = timeSlotMinutes - currentMinutes;
      if (timeSlotDifference >= 0 && timeSlotDifference < nearestTimeSlotDifference) {
        nearestTimeSlot = timeSlots[i];
        nearestTimeSlotDifference = timeSlotDifference;
      }
    }

    return nearestTimeSlot;
  };

  const setNextSalahTime = useCallback(() => {
    if (time != undefined && time != '') {
      const nearestSlot = getNearestTimeSLot(namaazTimeSlots);
      namaazTimeSlots.forEach((item, index) => {
        if (item === nearestSlot) {
          setNextSalahIndex(index);
        }
      });
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, newmasjidData]);
  return (
    <>
      <TableContainer sx={{ height: '100%' }}>
        <Table aria-label="simple table" sx={{ height: 'inherit', flex: 1 }}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Name</StyledTableCell>
              {/* <StyledTableCell>Azaan</StyledTableCell> */}
              <StyledTableCell align={newmasjidData.showQuran || newmasjidData.showFlyer ? 'center' : 'center'}>
                Iqamah
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody
            style={{
              justifyContent: 'center',
            }}
          >
            {newmasjidData &&
              newmasjidData.tvDisplayPrayerTimeItems &&
              newmasjidData.tvDisplayPrayerTimeItems.map((row, index) => {
                return (
                  <>
                    <StyledTableRow
                      key={row.name}
                      style={{
                        backgroundColor: nextSalahIndex === index ? '#B3DEC1' : undefined,
                        borderBottom:
                          index === newmasjidData.tvDisplayPrayerTimeItems.length - 1 ? '2px solid #075E55' : undefined,
                      }}
                    >
                      <StyledTableCell
                        align="center"
                        component="th"
                        scope="row"
                        style={{ fontWeight: nextSalahIndex === index ? '900' : '600' }}
                      >
                        {row.name}
                      </StyledTableCell>
                      {/* <StyledTableCell component="th" scope="row">
                        {row.azaan}
                      </StyledTableCell> */}
                      <StyledTableCell
                        align={newmasjidData.showQuran || newmasjidData.showFlyer ? 'center' : 'center'}
                        style={{ fontWeight: nextSalahIndex === index ? '900' : '600' }}
                      >
                        {row.timing}
                      </StyledTableCell>
                    </StyledTableRow>
                    {/* {index === newmasjidData.tvDisplayPrayerTimeItems.length - 1 ? (
                      <>
                        <TableRow style={{ border: '3px solid #075E55' }} />
                        <TableRow />
                      </>
                    ) : null} */}
                  </>
                );
              })}

            {newmasjidData &&
              newmasjidData.tvDisplayJumuahPrayerTimeItems &&
              newmasjidData.tvDisplayJumuahPrayerTimeItems.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell align="center" component="th" scope="row" style={{ fontWeight: '600' }}>
                    {row.name}
                  </StyledTableCell>
                  {/* <StyledTableCell component="th" scope="row">
                    {row.azaan}
                  </StyledTableCell> */}
                  <StyledTableCell
                    align={newmasjidData.showQuran || newmasjidData.showFlyer ? 'center' : 'center'}
                    style={{ fontWeight: '600' }}
                  >
                    {row.timing}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default NimazTimes;
