import React, { FunctionComponent } from 'react';
import { Router, Route, browserHistory, Redirect } from 'react-router';
import TvDisplayLayout from './layout/TvDisplayLayout';
import Home from './ui/organisms/Home';
import Page404 from './ui/organisms/Page404';
import './media.css';
const App: FunctionComponent = () => {
  return (
    <div>
      <Router history={browserHistory}>
        <Route exact path="/" component={Home} />
        <Route path="/masjid/:id" component={TvDisplayLayout} />
        <Route component={Page404} path="404" />
        <Redirect from="*" to="404" />
      </Router>
    </div>
  );
};

export default App;
