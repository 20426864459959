import { Box, Card, CardContent, Typography } from '@mui/material';
import React, { useEffect, useState, FunctionComponent } from 'react';
import { queryClient } from '../../api';
import { QuranSurahItem } from '../../api/types';
import { useMasjid } from '../../context';

const Quran: FunctionComponent = () => {
  const { quranData } = useMasjid();

  const [quranSurah, setQuranSurah] = useState<Array<QuranSurahItem>>();
  useEffect(() => {
    if (quranData.data) {
      if (quranData.data[0].text.length >= 300 || quranData.data[0].text.length < 60) {
        refetchQuery();
      } else {
        setQuranSurah([quranData.data[1], quranData.data[0]]);
      }
    }
  }, [quranData, quranData.data]);
  const refetchQuery = async () => {
    await queryClient.refetchQueries(['quranData'], { active: true, exact: true });
  };

  return (
    <>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '5px',
          paddingBottom: '5px',
        }}
      >
        <Card raised={false}>
          <CardContent>
            {quranSurah &&
              quranSurah.map((i: { text: string }, v: number) => {
                return (
                  <>
                    <Typography key={v} p={2} variant={v % 2 === 0 ? 'h4' : 'h5'} pb={1} align="center">
                      {i.text}
                    </Typography>

                    <Typography p={2} variant={'h5'} align="center">
                      {v % 2 === 0 ? '' : `Surah ${quranSurah[1].surah.englishName} : ${quranSurah[1].surah.number}`}
                    </Typography>
                  </>
                );
              })}
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
export default Quran;
