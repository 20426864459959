/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
type methodType = 'GET';

const fetcher = (method: methodType, url: string) => {
  return fetch(url, {
    method: method,
  }).then((res) => res.json());
};

export default fetcher;
