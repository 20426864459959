/* eslint-disable react-hooks/exhaustive-deps */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import React, { FunctionComponent, useState, useEffect } from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Grid, Stack, TableFooter, TablePagination } from '@mui/material';

import { Link } from 'react-router';
import { useMasjid } from '../../context';
import { CircularProgress } from '@material-ui/core';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));
const Home: FunctionComponent = () => {
  const { data } = useMasjid();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [masjids, setMasjids] = useState<[{ Name: string; City: string; State: string; ID: number }]>();
  const [filteredMasjids, setFilteredMasjids] = useState<
    { Name: string; City: string; State: string; ID: number }[] | undefined
  >();

  const [page, setPage] = useState(0);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    data && data.masjids && setMasjids(data.masjids);
    data && data.masjids && setFilteredMasjids(data.masjids);
  }, [data]);

  const findMasjids = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setFilteredMasjids(masjids);
    } else {
      const filteredmasjid: { Name: string; City: string; State: string; ID: number }[] | undefined =
        masjids && masjids.filter((i) => i.Name.toLowerCase().match(event.target.value.toLowerCase()) && i);
      setFilteredMasjids(filteredmasjid);
    }
  };
  return (
    <Paper component={Stack} direction="column" justifyContent="center" sx={{ height: '100vh' }}>
      <Container>
        <TableContainer component={Paper}>
          <Toolbar>
            <Typography
              sx={{ flex: '1', textAlign: 'left', marginLeft: 10 }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Musalleen Tv Display
            </Typography>

            <Search sx={{ marginRight: '10' }}>
              <SearchIconWrapper>
                <SearchIcon color="primary" />
              </SearchIconWrapper>
              <StyledInputBase placeholder="Search…" inputProps={{ 'aria-label': 'search' }} onChange={findMasjids} />
            </Search>
          </Toolbar>
          <Table sx={{ minWidth: 850 }} aria-label="simple table">
            {data ? (
              <>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Typography sx={{ fontWeight: 'bold' }}>Masjid Name</Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                      Location
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                      Visit Tv Display
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredMasjids &&
                    filteredMasjids.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, id) => (
                      <TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                        <TableCell component="th" scope="row" align="center">
                          {row.Name}
                        </TableCell>
                        <TableCell align="center">{row.City && row.City + ' , ' + row.State}</TableCell>
                        <TableCell align="center">
                          <Button variant="contained" size="large" color="primary" sx={{ padding: '7px 20px' }}>
                            <Typography sx={{ flex: 1, textAlign: 'left' }}>
                              <Link to={`masjid/${row.ID}`} style={{ textDecoration: 'none', color: 'white' }}>
                                {'Visit Tv Display'}
                              </Link>
                            </Typography>

                            <ArrowForwardIosSharpIcon sx={{ fontSize: '18px' }} />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>

                <TableFooter>
                  <TableRow>
                    <TablePagination
                      labelRowsPerPage=""
                      rowsPerPageOptions={[]}
                      count={(masjids && masjids.length) || 0}
                      rowsPerPage={5}
                      page={page}
                      onPageChange={handleChangePage}
                    />
                  </TableRow>
                </TableFooter>
              </>
            ) : (
              <Grid container textAlign={'center'} alignItems={'center'}>
                <Grid item xs={24} p={5}>
                  <CircularProgress />
                </Grid>
              </Grid>
            )}
          </Table>
        </TableContainer>
      </Container>
    </Paper>
  );
};

export default Home;
