import format_ from 'date-fns/format';

type Format = 'date-only' | 'month-only' | 'month-date-year' | 'date-month-year';

type FormatLookup = {
  [key in Format]: string;
};

const formatLookup: FormatLookup = {
  'date-only': 'do',
  'month-only': 'MMM',
  'month-date-year': 'EEEE,d,MMMM, yyyy',
  'date-month-year': 'dd mmmm, yyyy',
};

const format = (date: Date, format: Format = false): string => format_(date, formatLookup[format]);

export default {
  format,
};
