import { Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import Marquee from 'react-fast-marquee';
import { useMasjid } from '../../context';
import { Box } from '@mui/material';

const Announcements: FunctionComponent = () => {
  const { newmasjidData } = useMasjid();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        textAlign: 'center',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <Marquee pauseOnHover gradient={false} speed={35}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {newmasjidData &&
            newmasjidData.announcements &&
            newmasjidData.announcements.map((announcement) => {
              return (
                <>
                  <Typography variant="h3" color="white" key={announcement.position}>
                    {announcement.body}
                    &nbsp; &nbsp;
                  </Typography>
                </>
              );
            })}
        </div>
      </Marquee>

      <Typography variant="body1" color={'white'}>
        created with ❤️ by Musalleen.com
      </Typography>
    </Box>
  );
};

export default Announcements;
