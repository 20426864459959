/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { createContext, ReactNode, useState, useContext } from 'react';
import { AnnouncementItems, FlyerItems, PageData, PrayerTimeItems, quranSurah } from '../api/types';
import { useQuery } from 'react-query';
import { fetcher } from '../api';

type TvDisplayContextProviderProps = {
  children: ReactNode;
};

type contextType = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setCurrentMasjidId: (id: number) => void;
  quranData: quranSurah;
  data: { masjids: [{ ID: number; City: string; State: string; Name: string }] };
  newmasjidData: PageData;
};
const APIContext = createContext<contextType>({} as contextType);

export function APIContextProvider({ children }: TvDisplayContextProviderProps) {
  const [currentMasjidId, setCurrentMasjidId] = useState<number | null>(null);
  const { data } = useQuery(
    'repoData',
    () => fetcher('GET', `${process.env.REACT_APP_PUBLIC_API_URL}/masjids-with-tv-displays-configured`),
    { refetchInterval: 30000 },
  );

  const quranData = useQuery(
    'quranData',
    async () => {
      const number = Math.floor(Math.random() * 6236) + 1;
      const quranApiUrl = `https://api.alquran.cloud/v1/ayah/${number}/editions/en.sahih,quran-uthmani`;
      return fetcher('GET', quranApiUrl).then((data) => data.data);
    },
    { refetchInterval: 50000 },
  );

  const masjidData = useQuery(
    ['masjidData', currentMasjidId],
    () => {
      if (currentMasjidId) {
        return fetcher('GET', `${process.env.REACT_APP_PUBLIC_API_URL}/tv-display/${currentMasjidId}`);
      }
      return null;
    },
    { refetchInterval: 30000 },
  );

  const newmasjidData = {} as PageData;
  newmasjidData.hasTvDisplayConfigured = masjidData && masjidData.data && masjidData.data.HasTvDisplayConfigured;
  newmasjidData.city = masjidData && masjidData.data && masjidData.data.City;
  newmasjidData.state = masjidData && masjidData.data && masjidData.data.State;
  newmasjidData.tvDisplayLogo = masjidData && masjidData.data && masjidData.data.TvDisplay.MasjidTvDisplayLogo;
  newmasjidData.showHadith = masjidData && masjidData.data && masjidData.data.TvDisplay.ShowHadith;
  newmasjidData.showQuran = masjidData && masjidData.data && masjidData.data.TvDisplay.ShowQuran;
  newmasjidData.showFlyer = masjidData && masjidData.data && masjidData.data.TvDisplay.ShowFlyer;
  newmasjidData.id = masjidData && masjidData.data && masjidData.data.ID;
  newmasjidData.name = masjidData && masjidData.data && masjidData.data.Name;
  if (masjidData.data && masjidData) {
    newmasjidData.announcements = masjidData.data.TvDisplay.TvDisplayAnnouncements.map(
      (item: {
        Body: string;
        Deleted: boolean;
        ID: number;
        Position: number;
        TvDisplayId: number;
      }): AnnouncementItems => {
        return {
          body: item.Body,
          deleted: item.Deleted,
          id: item.ID,
          position: item.Position,
          tvDisplayId: item.TvDisplayId,
        };
      },
    );
    newmasjidData.tvDisplayFlyers = masjidData.data.TvDisplay.TvDisplayFlyers.map(
      (item: { Deleted: boolean; Description: string; ID: number; ImageUrl: string; Title: string }): FlyerItems => {
        return {
          deleted: item.Deleted,
          description: item.Description,
          id: item.ID,
          imageUrl: item.ImageUrl,
          title: item.Title,
        };
      },
    );

    newmasjidData.tvDisplayJumuahPrayerTimeItems = masjidData.data.TvDisplay.TvDisplayJumuahPrayerTimeItems.map(
      (item: { ID: number; Name: string; Adhan: string; Timing: string; TvDisplayId: number }): PrayerTimeItems => {
        return {
          id: item.ID,
          name: item.Name,
          azaan: item.Adhan,
          timing: item.Timing,
          tvDisplayId: item.TvDisplayId,
        };
      },
    );
    newmasjidData.tvDisplayPrayerTimeItems = masjidData.data.TvDisplay.TvDisplayPrayerTimeItems.map(
      (item: { ID: number; Name: string; Adhan: string; Timing: string; TvDisplayId: number }): PrayerTimeItems => {
        return {
          id: item.ID,
          name: item.Name,
          azaan: item.Adhan,
          timing: item.Timing,
          tvDisplayId: item.TvDisplayId,
        };
      },
    );
  }
  return (
    <APIContext.Provider
      value={{
        data,
        setCurrentMasjidId,
        quranData,
        newmasjidData,
      }}
    >
      {children}
    </APIContext.Provider>
  );
}

export function useMasjid() {
  const context = useContext(APIContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
}
