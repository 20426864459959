import React, { FunctionComponent, memo, useState } from 'react';
import uq from '@umalqura/core';

import DateHelpers from '../../dates/helpers';
import { Box, Grid } from '@mui/material';
import { useMasjid } from '../../context';

const Dates: FunctionComponent = () => {
  const { newmasjidData } = useMasjid();
  const gregorianDate = DateHelpers.format(new Date(), 'month-date-year');
  const hijriDate = uq(DateHelpers.format(new Date(), 'date-month-year'));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [data, setData] = useState();
  return (
    <Grid
      container
      sx={{
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
      }}
    >
      {/*  Logo */}
      {newmasjidData.tvDisplayLogo && (
        <Grid item xs={4}>
          <img
            src={newmasjidData.tvDisplayLogo}
            style={{ height: '100px', width: '100px', borderRadius: '50%', border: '1px solid #075E55' }}
          />
        </Grid>
      )}
      {/* Gregorain Date */}

      <Grid item xs={4}>
        <Box pt={1}>
          <Box style={{ fontSize: '18px' }} fontWeight="fontWeightMedium">
            {gregorianDate.split(',')[0]}
          </Box>
          <Box fontWeight="fontWeightMedium" style={{ fontSize: '60px' }}>
            {gregorianDate.split(',')[1]}
          </Box>
          <Box fontWeight="fontWeightMedium">
            {gregorianDate.split(',')[2]}
            {gregorianDate.split(',')[3]}
          </Box>
        </Box>
      </Grid>

      {/* Hijri Date */}

      <Grid item xs={4}>
        <Box pt={1}>
          <Box style={{ fontSize: '18px' }} fontWeight="fontWeightMedium">
            {hijriDate.format('fullDate', 'ar').split('،')[0]}
          </Box>
          <Box fontWeight="fontWeightMedium" style={{ fontSize: '43px' }}>
            {hijriDate.format('fullDate', 'ar').split('،')[1].substring(0, 3)}
          </Box>
          <Box fontWeight="fontWeightMedium">
            {hijriDate.format('fullDate', 'ar').split('،')[1].substring(3)}
            {hijriDate.format('fullDate', 'ar').split('،')[2]}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default memo(Dates);
